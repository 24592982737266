<template>
  <el-dialog
    title="分类"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="close"
  >
    <el-form ref="dataForm" label-width="80px" :rules="rules" :model="temp">
      <el-form-item label="分类名称" prop="tName">
        <el-input v-model="temp.tName"></el-input>
      </el-form-item>
      <!-- <el-form-item label="图标路径" prop="tPath">
        <Upload :imgList="imgAy" @uploadFiles="upload_Files" />
      </el-form-item> -->
      <el-form-item label="排序" prop="tSort">
        <el-input v-model="temp.tSort"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
// import Upload from "../components/Upload.vue";
export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row_type: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    row_type(val) {
      Object.assign(this.temp, val);
    },
    show(val) {
      this.dialogVisible = val;
      if (val) {
        this.upfun();
      }
    },
  },
  data() {
    return {
      imgAy: [{ url: "" }],
      dialogVisible: false,
      temp: {
        id: 0,
        tName: "",
        tPath: "",
        tSort: 100,
      },
      rules: {
        tName: [
          {
            required: true,
            message: "分类名称必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    upfun() {
      this.$nextTick(() => {
        // console.log(JSON.stringify(this.temp.tPath));
        // this.imgAy = [{ url: this.temp.tPath }];
        if (this.temp.tPath.indexOf("http") > -1) {
          this.imgAy = [{ url: this.temp.tPath }];
        } else {
          this.imgAy = [];
        }
      });
    },
    upload_Files(ay) {
      // console.log(ay.imgs);
      let ay_img = ay.imgs;
      this.temp.tPath = ay_img[ay_img.length - 1];
    },
    update() {
      this.$emit("update");
    },
    close() {
      this.$nextTick(() => {
        this.imgAy = [];
      });

      this.$emit("close");
    },
    save() {
      console.log(this.temp);
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          // this.temp.id = 0; // mock a id
          this.$api.news_types_save(this.temp).then((res) => {
            this.close();
            this.update();
            this.$notify({
              title: "温馨提示",
              message: res.data.msg,
              type: "success",
              duration: 2000,
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
